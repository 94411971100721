var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brand-list" },
    [
      _vm.cateFilterMenus.submenu.length && !_vm.isShowMap
        ? _c("BrandFilter", {
            attrs: {
              moreMenusData: _vm.brandFilterMenus,
              cateMenusData: _vm.cateFilterMenus.submenu,
              filterData: _vm.curFilterData,
              filterSelectCategory: _vm.filterSelectCategory,
            },
            on: {
              selectFilter: _vm.selectFilter,
              getCategory: _vm.getCategory,
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "brand-goods-wrap",
          class: {
            "brand-goods-wrap-style2":
              (_vm.curFilterData.industry &&
                _vm.curFilterData.industry.length === 0) ||
              !_vm.curFilterData.industry,
          },
        },
        [
          _c("HighLowFilter", {
            attrs: { filterData: _vm.highLowFilterData },
            on: { selectFilter: _vm.highLowFilterHandler },
          }),
          _c(
            "div",
            { staticClass: "brand-goods-list" },
            [
              _vm._l(_vm.brandList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "brand_" + item.code + "_" + index,
                    staticClass: "goods-list-item",
                  },
                  [
                    _c("div", { staticClass: "left" }, [
                      _c(
                        "div",
                        {
                          staticClass: "img-wrap cur-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goBrandDetail(item.code)
                            },
                          },
                        },
                        [
                          item.pic
                            ? _c("img", {
                                staticClass: "left-img",
                                attrs: { src: item.pic, alt: "" },
                              })
                            : [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "pic-name text-ellipsis2 center-middle-box",
                                  },
                                  [_vm._v(" " + _vm._s(item["品牌名称"]))]
                                ),
                                _c("img", {
                                  staticClass: "left-img",
                                  attrs: {
                                    src: require("../../assets/images/business/logobg2.png"),
                                    alt: "",
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                      _c("div", { staticClass: "goods-msg" }, [
                        _c("div", { staticClass: "top" }, [
                          _c("span", { staticClass: "goods-title" }, [
                            _vm._v(_vm._s(item["品牌名称"])),
                          ]),
                          item["业态分类"]
                            ? _c("span", { staticClass: "goods-title-tag" }, [
                                _vm._v(_vm._s(item["业态分类"])),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "bottom" }, [
                          _c("span", { staticClass: "goods-msg-desc" }, [
                            _vm._v("人均消费："),
                            _c("span", [
                              _vm._v(_vm._s(item["人均消费"] || "--") + "元"),
                            ]),
                          ]),
                          _c("span", { staticClass: "goods-msg-desc" }, [
                            _vm._v("门店数量："),
                            _c("span", [
                              _vm._v(_vm._s(item["门店数量"] || 0) + "家"),
                            ]),
                          ]),
                          _c("span", { staticClass: "goods-msg-desc" }, [
                            _vm._v("覆盖省："),
                            _c("span", [
                              _vm._v(_vm._s(item["覆盖省数量"] || 0)),
                            ]),
                          ]),
                          _c("span", { staticClass: "goods-msg-desc" }, [
                            _vm._v("覆盖市："),
                            _c("span", [
                              _vm._v(_vm._s(item["覆盖市数量"] || 0)),
                            ]),
                          ]),
                          _c("span", { staticClass: "goods-msg-desc" }, [
                            _vm._v("入驻购物中心："),
                            _c("span", [
                              _vm._v(
                                _vm._s(item["已入驻购物中心数量"] || 0) + "家"
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "right" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !item.isCollect,
                              expression: "!item.isCollect",
                            },
                          ],
                          staticClass: "collection",
                          on: {
                            click: function ($event) {
                              return _vm.addCollect(item.code)
                            },
                          },
                        },
                        [_vm._v(" 收藏 ")]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.isCollect,
                              expression: "item.isCollect",
                            },
                          ],
                          staticClass: "cancel-collection",
                          on: {
                            click: function ($event) {
                              return _vm.delCollect(item.code)
                            },
                          },
                        },
                        [_vm._v(" 取消收藏 ")]
                      ),
                      _c("div", { staticClass: "detail" }, [
                        _c(
                          "span",
                          {
                            staticClass: "detail-txt",
                            on: {
                              click: function ($event) {
                                return _vm.goBrandDetail(item.code)
                              },
                            },
                          },
                          [_vm._v("查看详情")]
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              !_vm.isLoading && _vm.brandList.length === 0
                ? _c(
                    "div",
                    { staticClass: "no-data" },
                    [_c("no-data", { staticClass: "center-middle-box" })],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "page-wrap" },
            [
              !_vm.isShowMap && !_vm.isLogin()
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "visitor-login",
                        class: { "g-brandList-login": !_vm.isLogin() },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "visitor-login-btn",
                            attrs: { role: "button" },
                            on: { click: _vm.gotoLogin },
                          },
                          [
                            _vm._v(" 登录查看全部信息"),
                            _c("img", {
                              staticClass: "visitor-login-icon",
                              attrs: {
                                src: require("@/assets/images/double-arror.png"),
                                width: "14",
                                height: "14",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                : [
                    _c("Page", {
                      attrs: {
                        current: _vm.page,
                        "show-sizer": "",
                        total: _vm.total,
                        "show-total": "",
                        "show-elevator": "",
                        "page-size": _vm.pageSize,
                        "page-size-opts": [_vm.pageSize],
                      },
                      on: {
                        "on-change": _vm.handlePageChange,
                        "on-page-size-change": _vm.handlerPageSizeChange,
                        "on-prev": _vm.handlePageChange,
                        "on-next": _vm.handlePageChange,
                      },
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }