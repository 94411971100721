<template>
  <div class="brand-filter">
    <div class="brand mrt">
      <div class="filter-title">品牌分类：</div>
      <div class="brand-item-wrap">
        <div
          class="brand-item mrt10"
          :class="{ 'active-title': checkFilterMenu(item, subMenu) }"
          @click="selectMenuCate(item, index)"
          v-for="(item, index) in cateMenusData"
          :key="item.code"
        >
          {{ item.name }}
        </div>
      </div>
      <Poptip trigger="click" placement="bottom" v-model="isOpenCategory" popper-class="custom-poptip">
        <div class="tooltip">
          <img width="14px" height="14px" src="../../assets/images/brand/search.png" alt="">检索品类
        </div>
        <template #content>
          <BrandSearch @getVal="getCategory($event, true)"></BrandSearch>
        </template>
      </Poptip>
    </div>
    <div
      class="brand"
      v-show="subMenu && subMenu.length > 1 && subMenu[0].name !== '不限'"
    >
      <div class="filter-title"></div>
      <div class="brand-item-wrap sub-brand-wrap">
        <div
          class="brand-item"
          v-for="(subItem, index) in subMenu"
          :key="subItem.code"
          :class="{
            'active-title': curSubMenu.code === subItem.code,
          }"
          @click="selectMenuCate(subItem, index)"
        >
          {{ subItem.name }}
        </div>
      </div>
    </div>
    <div
        class="brand"
        v-show="supMenu.length"
    >
      <div class="filter-title"></div>
      <div class="brand-item-wrap sub-brand-wrap bt">
        <div
            class="brand-item"
            v-for="(subItem, index) in supMenu"
            :key="subItem.code"
            :class="{
            'active-title': checkFilterSub(subItem),
          }"
            @click="selectCateFilter1(subItem, index)"
        >
          {{ subItem.name }}
        </div>
      </div>
    </div>
    <div
      class="more-filter"
      :class="{
        'more-filter-style2':
          subMenu && subMenu.length === 1 && subMenu[0].label === '不限',
      }"
    >
      <div class="filter-title">更多筛选：</div>
      <div class="more-wrap" ref="moreWrap">
        <div class="more-item-wrap">
          <div
            class="more-item"
            v-for="(item, index) in moreMenusData"
            :key="'more-item' + item.label + index"
          >
            <span :class="{ 'active-title': checkFilterCur2(item) }">{{
              item.label
            }}</span>
            <!-- <img
              v-show="item.isVip"
              src="../../assets/images/brand/vip-icon@2x.png"
              alt=""
              class="img-vip"
            /> -->
              <Icon  v-show="item.isVip" type="ios-lock-outline"  class="img-vip"/>
          </div>
        </div>
        <transition name="slide-up">
          <div class="more-select" v-show="showMoreSelectBox">
            <div
              class="more-select-item"
              v-for="(item, index) in moreMenusData"
              :key="'more-select' + item.label + index"
            >
              <span class="more-select-item-title">{{ item.label }}</span>

              <span class="more-select-item-span-wrap">
                <span
                  class="more-select-item-span"
                  v-for="(subItem, subIndex) in item.submenu"
                  :key="'more-select' + item.label + subItem.label + subIndex"
                  :class="{ 'active-title': checkFilterCur(item, subItem) }"
                  @click="selectMenuFilter(item, subItem)"
                >
                  {{ subItem.label }}
                </span>
              </span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { debuglog } from "util";
import filter from "./filter";
import BrandSearch from "../../views/BrandSearch.vue";
export default {
  name: "brandFilter",
  props: {
    moreMenusData: {
      type: Array,
      require: true,
      default: () => {
        return [
          {
            label: "经营方式",
            key: "businessType",
            submenu: [
              {
                label: "不限",
                value: "0",
              },
            ],
          },
        ];
      },
    },
    cateMenusData: {
      type: Array,
      require: true,
      default: () => {
        return [
          {
            label: "行业品类",
            key: "industry",
            submenu: [
              {
                label: "不限",
                value: "0",
              },
            ],
          },
        ];
      },
    },
    filterData: {
      type: Object,
      require: true,
      default: () => {},
    },
    filterSelectCategory: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      searchKey: '',
      showMoreSelectBox: false,
      isCommonFilter: true,
      // filterValue: {},
      cateMenusCurrIndex: 0,
      curMenu: undefined,
      curSubMenu: {},
      subMenu: [],
      supMenu: [],
      isOpenCategory: false
    };
  },
  components: {
    BrandSearch
  },
  mixins: [filter],
  methods: {
    getCategory (val, isClick) {
      this.isOpenCategory = false
      if (val.level === 1) {
        const index = this.cateMenusData.findIndex(item => item.code === val.code)
        const select = this.cateMenusData[index]
        this.subMenu = select.node
        this.curSubMenu = select.node[0]
        if (isClick) {
          this.sendVal(this.curSubMenu)
        }
      } else if (val.level === 2) {
        const [pid, id] = val.code.split('_');
        const pSelectIndex = this.cateMenusData.findIndex(item => item.code === pid)
        // 二级分类列表
        this.subMenu = this.cateMenusData[pSelectIndex].node
        this.curSubMenu = this.subMenu.find(item => item.code === val.code)
        // 三级分类列表
        if (this.curSubMenu && this.curSubMenu.node.length) {
          this.supMenu = this.curSubMenu.node
          this.curMenu = this.curSubMenu.node[0]
          if (isClick) {
            this.sendVal(this.curMenu)
          }

        } else {
          if (isClick) {
            this.sendVal(this.curSubMenu)
          }
        }
      } else {
        const [pid, id] = val.code.split('_');
        const pSelectIndex = this.cateMenusData.findIndex(item => item.code === pid)
        // 二级分类列表
        this.subMenu = this.cateMenusData[pSelectIndex].node
        this.curSubMenu = this.subMenu.find(item => item.code === pid + '_' + id)
        // 三级分类列表
        this.supMenu = this.curSubMenu.node
        this.curMenu = this.curSubMenu.node.find(item => item.code === val.code)
        if (isClick) {
          this.sendVal(this.curMenu)
        }
      }
    },
    handleSearch () {

    },
    sendVal (val) {
      this.$emit('getCategory', val)
    },
    selectCateFilter1 (item, index) {
      this.curMenu = item
      this.sendVal(this.curMenu)
    },
    checkFilterSub (subItem) {
      if (this.curMenu && this.curMenu.code === subItem.code) {
        return true
      }
      return false
    },
    getSubMenu(item) {
      this.subMenu = item.node;
    },
    selectMenuCate(item, index) {
      this.cateMenusCurrIndex = index;
      if (item.level === 1) {
        this.supMenu = []
        if (!item.code) {
          this.subMenu = []
          this.sendVal({})
          return
        }
        this.getSubMenu(item);
        this.curSubMenu = item.node[0]
        this.sendVal(this.curSubMenu)
      } else {
        this.curSubMenu = item
        this.supMenu = item.node
        this.curMenu = item.node[0]
        this.sendVal(this.curMenu || this.curSubMenu)
      }
    },
    checkFilterCur2(pItem) {
      const { key } = pItem;
      const filterKey = Object.keys(this.filterValue);
      if (filterKey.includes(key)) {
        if (this.filterValue[key].length > 0) {
          return true;
        }
      }
      return false;
    },
    selectMenuFilter(pItem, item) {
      if (pItem.isVip) {
        if (!this.$global.checkVIP()) {
          this.$global.gotoVIP();
          return;
        }
      }
      this.selectFilter(pItem, item);
    },
    getFilterValue() {
      this.filterValue = this.filterData;
      if (this.filterValue["industry"]) {
        for (let i = 0, len = this.cateMenusData.length; i < len; i++) {
          const vaule = this.filterValue["industry"][0];
          if (vaule && vaule.split("_")[0] === this.cateMenusData[i].value) {
            if (this.cateMenusCurrIndex !== i) {
              this.subMenu = this.cateMenusData[i].submenu;
            }
          }
        }
      }
    },
    eventwindowClickHandler(e) {
      if (this.$refs.moreWrap.contains(e.target)) {
        if (!this.showMoreSelectBox) {
          this.showMoreSelectBox = true;
        }
      } else {
        if (this.showMoreSelectBox) {
          this.showMoreSelectBox = false;
        }
      }
    },
  },

  created() {
    if (this.subMenu.length == 0) {
      this.subMenu = this.cateMenusData[0].submenu;
    }
    if (this.filterSelectCategory.code) {
      this.getCategory(this.filterSelectCategory)
    }
  },
  mounted() {
    //  this.initData()
    document.addEventListener("click", this.eventwindowClickHandler);
  },
  destroyed() {
    document.removeEventListener("click", this.eventwindowClickHandler);
    this.showMoreSelectBox = false;
  },
};
</script>

<style lang="less" scoped>
.tooltip {
  color: #3519FB;
  line-height: 28px;
  width: 106px;
  height: 28px;
  background: #F2F2F9;
  border-radius: 21px;
  text-align: center;
  cursor: pointer;
  img {
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 8px;
  }
}
.brand-filter {
  width: 1400px;
  min-width: 1400px;
  // height: 165px;
  background: #ffffff;
  border-radius: 4px;
  margin: 18px auto 16px auto;
  padding: 24px;
  .filter-title {
    width: 70px;
    height: 16px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 16px;
  }
}

.brand {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  .mrt10 {
    margin-bottom: 10px;
  }
  &.mrt {
    margin-bottom: 6px;
  }
  .bt {
    border-top: 1px solid rgba(231,231,231,0.8);
  }
  .brand-item-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 16px;
    width: 965px;
    .brand-item {
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      line-height: 22px;
      margin-right: 32px;
      cursor: pointer;
      &:hover {
        color: #3519fb !important;
      }
    }
  }
  .sub-brand-wrap {
    background: rgba(247,248,254,0.9);
    padding: 12px 0 11px 16px;
    width: 100%;
  }
}
.active-title {
  color: #3519fb !important;
  font-weight: 400 !important;
}
.more-filter-style2 {
  margin-top: 32px !important;
}
.more-filter {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  margin-top: 24px;
  .more-wrap {
    width: 1074px;
    position: relative;

    .more-item-wrap {
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .more-item {
        width: 143px;
        height: 100%;
        position: relative;

        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #eeeeee;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          line-height: 20px;
          margin-left: 16px;
        }
        .img-vip {
          width: 33px;
          height: 16px;
          position: absolute;
          left: 15px;
          top: -9px;
        }
        &::after {
          content: "";
          font-size: 0;
          position: absolute;
          right: 10px;
          top: calc(50% - 4px);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px;
          border-top-width: 6px;
          border-bottom-width: 0px;

          border-color: transparent;
          border-top-color: #999999;
        }
      }
    }
    .more-select {
      position: absolute;
      top: 38px;
      left: 0;
      right: 0;
      // width: 1058px;
      height: 364px;
      background: rgba(255, 255, 255, 0.99);
      box-shadow: 0px 2px 17px 6px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(16px);
      z-index: 1;
      padding-top: 16px;
      padding-bottom: 4px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      .more-select-item {
        width: 143px;
        height: 100%;
        .more-select-item-title {
          margin-left: 16px;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
        .more-select-item-span-wrap {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .more-select-item-span {
            cursor: pointer;
            margin-left: 16px;
            margin-bottom: 20px;
            &.active,
            &.selected,
            &:hover {
              color: #3519fb;
            }
          }
        }
      }
    }
  }
}
</style>
