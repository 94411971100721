<template>
  <div class="site-wrap">
    <Header :mode="2" :searchKey="searchKey" @search="doSearch"></Header>
    <div class="main-wrap">
      <BrandMap
        ref="brandMap"
        v-show="isShowMap"
        :filterData="filterData"
        :pages="pages"
        :searchKey="searchKey"
        :filterSelectCategory="categoryData"
        @changeFilter="changeFilter"
        @changePage="changePage"
        @categoryData="getCategoryData"
      ></BrandMap>
      <BrandList
        ref="brandList"
        v-show="!isShowMap"
        :pages="pages"
        :filterData="filterData"
        :searchKey="searchKey"
        :filterSelectCategory="categoryData"
        :isShowMap="isShowMap"
        @changeFilter="changeFilter"
        @changePage="changePage"
        @categoryData="getCategoryData"
      ></BrandList>
      <div
        class="change-btn"
        :class="{ 'change-btn-list': !isShowMap }"
        @click="showMapOrList"
      >
        <template v-if="isShowMap">
          <Icon class="change-btn-icon" custom="icon-mode-list"></Icon>
          列表模式
        </template>
        <template v-else>
          <Icon class="change-btn-icon" custom="icon-mode-map"></Icon>
          地图模式
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import BrandMap from "./BrandMap.vue";
import BrandList from "./BrandList.vue";
import { brandFilterMenus, brandCates } from "js/brandData.js";
import { GetBrandTree } from '@/service';
export default {
  name: "brand",
  provide() {
    return {
      brandFilterMenus: brandFilterMenus,
      cateFilterMenus: this.brandCates1,
    };
  },
  metaInfo: {
    title: "查品牌",
     meta: [
      {
        name: "keywords",
        content:
          "千里目,品牌数据,门店数据,门店分布,对比分析,品牌分类,品牌认领,覆盖省市排行,门店数量排行,人均消费,实时客流,客流统计,商品销量,广告投放,入驻商场数量,经营方式",
      },
      {
        name: "description",
        content:
          "千里目覆盖餐饮、零售、汽车、生活服务等十几个行业和全国800万+门店，具备精准搜索、通过地图快速定位、对比分析、品牌认领等功能，支持查看品牌门店位置分布、覆盖省市排行、门店数量排行、人均消费、日均客流、日均销量、日均广告投放、入驻商场数量等详细数据。",
      },
    ],
  },
  data() {
    return {
      isShowMap: true,
      filterData: {},
      pages: {},
      searchKey: "",
      brandCates1: {
        submenu: []
      },
      categoryData: {}
    };
  },
  computed: {},
  methods: {
    getCategoryData (val) {
      this.categoryData = val
      console.log('this.categoryData123',this.categoryData);
    },
    changeFilter(filterData) {
      this.filterData = filterData;
    },
    changePage(pageData) {
      this.pages = pageData;
    },
    showMapOrList() {
      this.isShowMap = !this.isShowMap;
      this.initData();
    },
    initData() {
      if (this.isShowMap) {
        this.$refs.brandMap.initData();
      } else {
        this.$refs.brandList.initData();
      }
    },
    getfilterData() {
      return this.filterData;
    },
    setFilterData(filterData) {
      this.filterData = filterData;
    },
    doSearch(searchKey) {
      this.searchKey = searchKey;
      this.$nextTick(() => {
        if (this.isShowMap) {
          this.$refs.brandMap.doSearch();
        } else {
          this.$refs.brandList.doSearch();
        }
      });
    },
    async getBrandCategory () {
      const res = await GetBrandTree()
      const data = res.data.items || []
      data.unshift({
        name: '全部',
        code: undefined,
        level: 1,
        node: []
      })
      this.brandCates1.submenu = data
      console.log('getBrandCategory', res);
    }
  },
  async mounted() {
    let searchKey = this.$route.query.key;
    if (searchKey) {
      this.searchKey = searchKey;
    }
    await this.getBrandCategory()
    this.$nextTick(() => {
      this.initData();
    });
  },
  components: {
    BrandMap,
    BrandList,
  },
};
</script>

<style lang="less">
.change-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  font-size: 14px;
  line-height: 32px;
  border-radius: 4px;
  padding: 0 10px;
  color: hsla(247, 96%, 59%, 1);
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12);
  transition: 0.2s;
  cursor: pointer;
  user-select: none;
  .change-btn-icon {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 4px;
  }
  &:hover {
    background: hsla(245, 68%, 93%, 1);
  }
  &.change-btn-list {
    right: 0px;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid hsla(247, 97%, 54%, 0.24);
    box-shadow: none;
    &:hover {
      background: hsla(247, 97%, 54%, 0.1);
    }
  }
}
</style>
<style lang="less" scoped>
/deep/.map-panel {
  .map-tools {
    right: 132px;
  }
}
</style>
