<template>
  <div class="brand-list">
    <BrandFilter
      v-if="cateFilterMenus.submenu.length && !isShowMap"
      :moreMenusData="brandFilterMenus"
      :cateMenusData="cateFilterMenus.submenu"
      :filterData="curFilterData"
      :filterSelectCategory="filterSelectCategory"
      @selectFilter="selectFilter"
      @getCategory="getCategory"
    >
    </BrandFilter>
    <div
      class="brand-goods-wrap"
      :class="{
        'brand-goods-wrap-style2':
          (curFilterData.industry && curFilterData.industry.length === 0) ||
          !curFilterData.industry,
      }"
    >
      <HighLowFilter
        @selectFilter="highLowFilterHandler"
        :filterData="highLowFilterData"
      ></HighLowFilter>
      <div class="brand-goods-list">
        <div
          class="goods-list-item"
          v-for="(item, index) in brandList"
          :key="'brand_' + item.code + '_' + index"
        >
          <div class="left">
            <div class="img-wrap cur-pointer" @click="goBrandDetail(item.code)">
              <img class="left-img"  v-if="item.pic" :src="item.pic" alt="" />
              <template v-else>
                <span class="pic-name text-ellipsis2 center-middle-box">
                  {{ item["品牌名称"] }}</span>
                <img class="left-img" src="../../assets/images/business/logobg2.png" alt="">
              </template>
            </div>
            <div class="goods-msg">
              <div class="top">
                <span class="goods-title">{{ item["品牌名称"] }}</span>
                <span class="goods-title-tag" v-if="item['业态分类']">{{
                  item["业态分类"]
                }}</span>
              </div>
              <div class="bottom">
                <span class="goods-msg-desc"
                  >人均消费：<span>{{ item["人均消费"] || "--" }}元</span>
                </span>
                <span class="goods-msg-desc"
                  >门店数量：<span>{{ item["门店数量"] || 0 }}家</span>
                </span>
                <span class="goods-msg-desc"
                  >覆盖省：<span>{{ item["覆盖省数量"] || 0 }}</span>
                </span>
                <span class="goods-msg-desc"
                  >覆盖市：<span>{{ item["覆盖市数量"] || 0 }}</span>
                </span>
                <span class="goods-msg-desc"
                  >入驻购物中心：<span
                    >{{ item["已入驻购物中心数量"] || 0 }}家</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="right">
            <div
              class="collection"
              v-show="!item.isCollect"
              @click="addCollect(item.code)"
            >
              收藏
            </div>
            <div
              class="cancel-collection"
              v-show="item.isCollect"
              @click="delCollect(item.code)"
            >
              取消收藏
            </div>

            <div class="detail">
              <span class="detail-txt" @click="goBrandDetail(item.code)"
                >查看详情</span
              >
              <!-- <img src="../../assets/images/icon/arrow_blue.png" alt="" class="right-icon" /> -->
            </div>
          </div>
        </div>
        <div class="no-data" v-if="!isLoading && brandList.length === 0">
          <no-data class="center-middle-box"></no-data>
        </div>
      </div>
      <div class="page-wrap">
        <template v-if="!isShowMap && !isLogin()">
          <div
            class="visitor-login"
            :class="{ 'g-brandList-login': !isLogin() }"
          >
            <div role="button" class="visitor-login-btn" @click="gotoLogin">
              登录查看全部信息<img
                class="visitor-login-icon"
                :src="require('@/assets/images/double-arror.png')"
                width="14"
                height="14"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <Page
            :current="page"
            show-sizer
            :total="total"
            show-total
            show-elevator
            :page-size="pageSize"
            :page-size-opts="[pageSize]"
            @on-change="handlePageChange"
            @on-page-size-change="handlerPageSizeChange"
            @on-prev="handlePageChange"
            @on-next="handlePageChange"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import BrandFilter from "../../components/filter/BrandFilter.vue";
import HighLowFilter from "../../components/filter/HighLowFilter.vue";
import { getParams } from "../../assets/javascript/brandData";
import brandMixin from "./brand.js";
import { login, isLogin } from "../../assets/javascript/utils.js";
import collectMixin from "@/mixins/collect.js";

export default {
  name: "BrandList",
  props: {
    filterData: {
      type: Object,
      require: true,
      default: () => {
        return {};
      },
    },
    isShowMap: {
      type: Boolean,
    },
    searchKey: {
      type: String,
      default: "",
    },
    filterSelectCategory: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  inject: ["brandFilterMenus", "cateFilterMenus"],
  mixins: [brandMixin, collectMixin],
  data() {
    return {
      filterSelectData: {},
      highLowFilterData: ["", ""],
      defaultImg: require("../../assets/images/normal_brand.png"),
    };
  },
  methods: {
    getGoodsList() {},
    initFilterData() {},
    handleSelectBrand() {},
    isLogin,
    gotoLogin: login,
    highLowFilterHandler(val) {
      this.highLowFilterData = val;
      this.page = 1;
      this.initTable();
    },
  },
  created() {},
  mounted() {
    // this.initData();
  },
  components: {
    BrandFilter,
    HighLowFilter,
  },
};
</script>
<style lang="less" scoped>
.brand-list {
  line-height: 1;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  overflow: auto;
  background-color: #f6f6fa;
  position: relative;
  font-family: PingFangSC-Regular, PingFang SC;
  .brand-goods-wrap-style2 {
    height: calc(100% - 181px) !important;
  }
  .g-brandList-login {
    position: static !important;
    position: static !important;
    height: 80px !important;
    margin: 0 auto;
  }
  .brand-goods-wrap {
    max-width: 1400px;
    height: calc(100% - 211px);
    margin: 0 auto;
    background-color: #fff;
    position: relative;

    .brand-goods-list {
      min-height: calc(100% - 157px);
      background-color: #fff;
      // overflow: auto;
      .goods-list-item {
        background-color: #fff;
        // background-color: plum;
        height: 120px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        &::after {
          content: "";
          position: absolute;
          left: 24px;
          right: 24px;
          bottom: 0;
          height: 0;
          opacity: 0.3;
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        }
        .left {
          height: 100%;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .img-wrap {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .left-img {
              width: auto;
              height: auto;
              max-height: 80px;
              max-width: 80px;
            }
            .pic-name {
              width: 54px;
              text-align: center;
              font-size: 18px;
              font-weight: 600;
              color: #fff;
              line-height: 1.5;
            }
          }

          .goods-msg {
            height: 100%;
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .top {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 28.5px;
              .goods-title {
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                line-height: 16px;
                margin-right: 10px;
              }
              .goods-title-tag {
                display: inline-block;
                height: 22px;
                border-radius: 6px;
                border: 1px solid #b1afc0;
                padding: 0 7px;
                display: flex;
                justify-content: center;
                align-items: center;

                margin-right: 32px;
                font-size: 12px;
                font-weight: 400;
                color: #848295;
                // line-height: 17px;
              }
            }
          }
          .bottom {
            margin-bottom: 28.5px;
            .goods-msg-desc {
              display: inline-flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.7);
              line-height: 16px;
              margin-right: 32px;
              span {
                color: rgba(0, 0, 0, 1);
              }
            }
          }
        }
        .right {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          position: relative;
          .collection {
            font-size: 14px;
            font-weight: 500;
            color: #f99000;
            line-height: 16px;
            margin-top: 26.5px;

            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 26px;
            background: rgba(249, 144, 0, 0.1);
            border-radius: 4px;
            cursor: pointer;
          }
          .cancel-collection {
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.6);
            line-height: 16px;
            margin-top: 26.5px;

            display: flex;
            justify-content: center;
            align-items: center;
            width: 78px;
            height: 26px;
            background: rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            cursor: pointer;
          }
          .detail {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 28.5px;
            background-image: url("../../assets/images/icon/arrow_blue.png");
            background-position: right center;
            background-size: 6px 11px;
            background-repeat: no-repeat;
            &:hover {
              background-image: url("../../assets/images/icon/arrow_blue_hover.png");
              .detail-txt {
                color: #5d47ff;
              }
            }
            .detail-txt {
              font-size: 14px;
              font-weight: 500;
              color: #3519fb;
              line-height: 16px;
              margin-right: 4px;
              padding-right: 10px;
              cursor: pointer;
            }
            .right-icon {
            }
          }
        }
      }
      .no-data {
        height: 300px;
      }
    }
    :deep(.page-wrap) {
      height: 85px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 24px;
      background: #fff;
      //   .ivu-page-total {
      //     font-size: 14px;
      //     font-weight: 400;
      //     color: #666666;
      //     line-height: 20px;
      //   }
      //   .ivu-select-selected-value {
      //     font-size: 14px;
      //     font-weight: 400;
      //     color: #333333;
      //     line-height: 32px;
      //     // height: 32px;
      //     background: #ffffff;
      //     border-radius: 2px;
      //     // border: 1px solid #dddddd !important;
      //   }
      //   .ivu-select-visible .ivu-select-selection {
      //     border-color: #3519fb;
      //     box-shadow: 0 0 0 2px hwb(247deg 10% 2% / 20%);
      //     border-radius: 2px;
      //   }
      //   .ivu-select-single .ivu-select-selection {
      //     border-radius: 2px;
      //   }
      //   .ivu-select-selection-focused,
      //   .ivu-select-selection:hover {
      //     border-color: #3519fb;
      //   }
      //   .ivu-select-item-selected,
      //   .ivu-select-item-selected:hover {
      //     color: #3519fb;
      //   }
      //   .ivu-page-item {
      //     background: #ffffff;
      //     border-radius: 2px;
      //     border: 1px solid #dddddd;
      //   }
      //   .ivu-page-item:hover {
      //     border-color: #3519fb;
      //   }
      //   .ivu-page-item-active {
      //     border-color: #3519fb;
      //   }
      //   .ivu-page-item-active a,
      //   .ivu-page-item-active:hover a {
      //     color: #3519fb;
      //   }
      //   .ivu-page-item:hover a {
      //     color: #3519fb;
      //   }
      //   .ivu-page-item-jump-next i:hover,
      //   .ivu-page-item-jump-prev i:hover,
      //   .ivu-page-item-jump-next i::after,
      //   .ivu-page-item-jump-prev i::after {
      //     color: #3519fb;
      //   }
      //   .ivu-page-next:hover,
      //   .ivu-page-prev:hover {
      //     border-color: #3519fb;
      //     background: #ffffff;
      //     border-radius: 2px;
      //     // border: 1px solid #dddddd;
      //   }
      //   .ivu-page-next:hover a,
      //   .ivu-page-prev:hover a {
      //     color: #3519fb;
      //   }
      //   .ivu-page-disabled:hover {
      //     border-color: #dddddd;
      //   }
      //   .ivu-page-disabled:hover a {
      //     color: #dddddd;
      //   }

      //   .ivu-page-options-elevator input:hover {
      //     color: #3519fb;
      //     border-color: #3519fb;
      //   }
      //   .ivu-page-options {
      //     margin-left: 4px;
      //   }
      //   .ivu-page-options-elevator input {
      //     height: 32px;
      //     background: #ffffff;
      //     border-radius: 2px;
      //     border: 1px solid #dddddd;
      //   }
      //   .ivu-page-next,
      //   .ivu-page-prev {
      //     height: 32px;
      //     background: #ffffff;
      //     border-radius: 2px;
      //     border: 1px solid #dddddd;
      //   }
      //   .ivu-page-options-elevator input:focus {
      //     border-color: #3519fb;
      //     box-shadow: 0 0 0 2px rgb(53 25 251 / 20%);
      //   }
    }
  }
}
</style>
