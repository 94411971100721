var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MapListTemplate",
    { on: { changed: _vm.handleChangeExpand } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "left-panel no-scrollbar",
          class: { "no-pager": _vm.isHidePager },
          attrs: { slot: "leftPanel" },
          slot: "leftPanel",
        },
        [
          _c("div", { staticClass: "left-navs" }, [
            _c("ul", [
              _c(
                "li",
                { on: { click: _vm.hideCollectList } },
                [
                  _c(
                    "Poptip",
                    {
                      attrs: {
                        trigger: "click",
                        transfer: "",
                        placement: "top-start",
                        "popper-class": "custom-poptip",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c("Category", {
                                ref: "category",
                                attrs: { isSelectCategory: _vm.categoryData },
                                on: { getVal: _vm.getCategory },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.isOpenCategory,
                        callback: function ($$v) {
                          _vm.isOpenCategory = $$v
                        },
                        expression: "isOpenCategory",
                      },
                    },
                    [
                      _c(
                        "Tooltip",
                        {
                          attrs: {
                            content: _vm.cateShowLabel,
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "category-box",
                              class: {
                                active: _vm.cateShowLabel !== "品牌分类",
                              },
                            },
                            [
                              _c("Icon", {
                                attrs: { custom: "icon-industry" },
                              }),
                              _c("span", { staticClass: "category-name" }, [
                                _vm._v(" " + _vm._s(_vm.cateShowLabel) + " "),
                              ]),
                              _vm.cateShowLabel !== "品牌分类"
                                ? _c("Icon", {
                                    staticClass: "icon-status",
                                    attrs: {
                                      size: "14",
                                      type: "ios-close-circle-outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.clearCategory.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.hideCollectList } },
                [
                  _c(
                    "FilterBtn",
                    {
                      attrs: {
                        data: _vm.filterMenusData,
                        filterData: _vm.curFilterData,
                        isSelectClose: true,
                      },
                      on: { selectFilter: _vm.selectFilter },
                    },
                    [
                      _c("Icon", { attrs: { custom: "icon-filter" } }),
                      _vm._v(" 高级筛选 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  class: { active: _vm.isShowCollection },
                  on: { click: _vm.changeCollectShow },
                },
                [
                  _c("Icon", { attrs: { custom: "icon-favorite" } }),
                  _vm._v(" 我的收藏 "),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "left-list-content",
              class: { "g-login-padding": !_vm.isLogin() },
            },
            [
              _c(
                "ul",
                [
                  _vm._l(_vm.showBrandList, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: "brand_" + index,
                        class: { cur: _vm.curBrandId === item.code },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelectBrand(item.code)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "pic cur-pointer",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.goBrandDetail(item.code)
                              },
                            },
                          },
                          [
                            item.pic
                              ? _c("img", { attrs: { src: item.pic, alt: "" } })
                              : [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "pic-name text-ellipsis2 center-middle-box",
                                    },
                                    [_vm._v(" " + _vm._s(item["品牌名称"]))]
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/business/logobg2.png"),
                                      alt: "",
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                        _c("div", { staticClass: "list-cont" }, [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "title text-ellipsis",
                                attrs: { title: item["品牌名称"] },
                              },
                              [_vm._v(_vm._s(item["品牌名称"]))]
                            ),
                            item["业态分类"]
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "tag",
                                    style: _vm.getTagStyle(item),
                                    attrs: { title: item["业态分类"] },
                                  },
                                  [_vm._v(_vm._s(item["业态分类"]))]
                                )
                              : _vm._e(),
                            !item.isCollect
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "collect",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.addCollect(item.code)
                                      },
                                    },
                                  },
                                  [_vm._v("收藏")]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "collect no-collect",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delCollect(item.code)
                                      },
                                    },
                                  },
                                  [_vm._v("取消收藏")]
                                ),
                          ]),
                          _c("div", { staticClass: "list-item" }, [
                            _c("span", { staticClass: "list-item2" }, [
                              _vm._v(" 人均消费: "),
                              _c("a", [
                                _vm._v(_vm._s(item["人均消费"] || "--") + "元"),
                              ]),
                            ]),
                            _c("span", { staticClass: "list-item2" }, [
                              _vm._v(" 门店数量: "),
                              _c("a", [
                                _vm._v(_vm._s(item["门店数量"] || 0) + "家"),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "list-item" }, [
                            _vm._v(" 入驻购物中心: "),
                            _c("a", [
                              _vm._v(
                                _vm._s(item["已入驻购物中心数量"] || 0) + "家"
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "link hover-show",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.goBrandDetail(item.code)
                                  },
                                },
                              },
                              [_vm._v("查看详情")]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "cur-box" }),
                        _c("img", {
                          staticClass: "triangle-icon",
                          attrs: {
                            src: require("../../assets/images/icon/triangle.png"),
                            alt: "",
                          },
                        }),
                      ]
                    )
                  }),
                  !_vm.isLogin()
                    ? [
                        _c("li", { staticClass: "visitor-login" }, [
                          _c(
                            "div",
                            {
                              staticClass: "visitor-login-btn",
                              attrs: { role: "button" },
                              on: { click: _vm.gotoLogin },
                            },
                            [
                              _vm._v(" 登录查看全部信息"),
                              _c("img", {
                                staticClass: "visitor-login-icon",
                                attrs: {
                                  src: require("@/assets/images/double-arror.png"),
                                  width: "14",
                                  height: "14",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              !_vm.isLoading && _vm.showBrandList.length === 0
                ? _c(
                    "div",
                    { staticClass: "no-data" },
                    [_c("no-data", { staticClass: "center-middle-box" })],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("PagerLittle", {
            staticClass: "left-list-pager",
            attrs: { total: _vm.total, page: _vm.page, pageSize: _vm.pageSize },
            on: { "on-change": _vm.handlePageChange },
          }),
        ],
        1
      ),
      _c("MapPanel", {
        ref: "mapPanel",
        attrs: { isSelectCity: true, deep: _vm.deep },
        on: {
          loaded: _vm.handleMapLoaded,
          click: _vm.handleMapClick,
          contextmenu: _vm.handleBackDeep,
          selectCity: _vm.handleSelectCity,
          handleBackClick: _vm.handleBackDeep,
        },
      }),
      _vm.isMapLoading ? _c("Spin", { attrs: { fix: "" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }