var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter-low-high" }, [
    _c("div", { staticClass: "left" }),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c(
          "span",
          {
            staticClass: "first-item",
            class: { active: !_vm.currFilterData[0] && !_vm.currFilterData[1] },
            on: {
              click: function ($event) {
                return _vm.selectMenuFilter("", "")
              },
            },
          },
          [_vm._v("默认排序")]
        ),
        _vm._l(_vm.orderList, function (item, index) {
          return _c(
            "div",
            { key: item.label + index, staticClass: "other-item" },
            [
              _c(
                "span",
                {
                  class: { "no-cursor": item.isVip },
                  on: {
                    click: function ($event) {
                      return _vm.selectMenuFilter(item, "")
                    },
                  },
                },
                [_vm._v(_vm._s(item.label))]
              ),
              item.isVip
                ? _c("Icon", {
                    staticClass: "vip-img",
                    attrs: { type: "ios-lock-outline" },
                  })
                : _vm._e(),
              _c("div", { staticClass: "filter-icon" }, [
                _c("div", {
                  staticClass: "up",
                  class: {
                    "active-up": _vm.checkFilterCur(item, "asc"),
                    "no-cursor": item.isVip,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectMenuFilter(item, "asc")
                    },
                  },
                }),
                _c("div", {
                  staticClass: "down",
                  class: {
                    "active-down": _vm.checkFilterCur(item, "desc"),
                    "no-cursor": item.isVip,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectMenuFilter(item, "desc")
                    },
                  },
                }),
              ]),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }