<template>
  <div class="filter-low-high">
    <div class="left"></div>
    <div class="right">
      <span
        class="first-item"
        :class="{ active: !currFilterData[0] && !currFilterData[1] }"
        @click="selectMenuFilter('', '')"
        >默认排序</span
      >
      <div
        class="other-item"
        v-for="(item, index) in orderList"
        :key="item.label + index"
      >
        <span
          @click="selectMenuFilter(item, '')"
          :class="{ 'no-cursor': item.isVip }"
          >{{ item.label }}</span
        >
        <!-- <img
          v-if="item.isVip"
          src="../../assets/images/brand/vip-icon@2x.png"
          alt=""
          class="vip-img"
        /> -->

         <Icon  v-if="item.isVip" type="ios-lock-outline"   class="vip-img"/>
        <div class="filter-icon">
          <div
            class="up"
            :class="{
              'active-up': checkFilterCur(item, 'asc'),
              'no-cursor': item.isVip,
            }"
            @click="selectMenuFilter(item, 'asc')"
          ></div>
          <div
            class="down"
            :class="{
              'active-down': checkFilterCur(item, 'desc'),
              'no-cursor': item.isVip,
            }"
            @click="selectMenuFilter(item, 'desc')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { brandOrderList } from "../../assets/javascript/brandData";
export default {
  name: "HighLowFilter",
  props: {
    filterData: {
      type: Array,
      require: true,
      default: () => {
        return ["", ""];
      },
    },
  },
  watch: {
    filterData(val) {
      this.currFilterData.splice(0, 1, val[0]);
      this.currFilterData.splice(1, 1, val[1]);
    },
  },
  data() {
    return {
      currFilterData: [],
      orderList: brandOrderList,
    };
  },
  methods: {
    selectMenuFilter(pItem, desc) {
      if (pItem.isVip) {
        if (!this.$global.checkVIP()) {
          this.$global.gotoVIP();
          return;
        }
      }
      this.filterHandler(pItem, desc);
    },
    filterHandler(item, desc) {
      if (item.isVip) return;
      let tempFilter;
      if (desc) {
        tempFilter = [item.label, desc];
      } else {
        tempFilter = ["", ""];
      }
      this.$emit("selectFilter", tempFilter);
    },
    checkFilterCur(item, desc) {
      if (
        this.currFilterData[0] === item.label &&
        this.currFilterData[1] === desc
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
  created() {},
};
</script>
<style lang="less" scoped>
.filter-low-high {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 0;
    height: 0px;
    opacity: 0.3;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }
  .left {
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .first-item {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .other-item {
      margin-left: 32px;
      height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        cursor: pointer;
      }
      img,i {
        width: 32px;
        height: 16px;
        position: absolute;
        top: -14px;
        left: -2px;
      }
      .filter-icon {
        position: relative;
        width: 14px;
        height: 14px;
        margin-left: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        .up {
          font-size: 0;
          cursor: pointer;

          margin-top: 1px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px;
          border-bottom-width: 5px;
          border-top-width: 0;

          border-color: transparent;
          border-bottom-color: #999999;
        }
        .down {
          font-size: 0;
          cursor: pointer;

          margin-bottom: 1px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px;
          border-top-width: 5px;
          border-bottom-width: 0;
          border-color: transparent;
          border-top-color: #999999;
        }
      }
    }
  }
  .active {
    color: #3519fb !important;
  }
  .active-down {
    border-top-color: #3519fb !important;
  }
  .active-up {
    border-bottom-color: #3519fb !important;
  }
  .no-cursor {
    // cursor: not-allowed !important;
  }
}
</style>
