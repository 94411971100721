import { SearchData, GetCommonInfo, GetDetailBrowsingRecord } from "@/service";

import {
  brandNormalOrder,
  brandFilterMenus,
  getFilter,
  getSortKey,
  getCateFilter,
} from "js/brandData.js";
import {
  cloneObj,
  formatFieldsList,
  formatList,
  login,
  openNewWindow,
} from "js/utils.js";
import { User } from "../../service/user";
const brandMixin = {
  data() {
    return {
      isLoading: false,
      page: 1,
      pageSize: 20,
      total: 0,
      brandList: [],
      curFilterData: {},
      isFirstGetBrandList: true,
      tableFields: [
        "品牌id",
        "品牌名称",
        "标签",
        "人均消费",
        "门店数量",
        "覆盖省数量",
        "覆盖市数量",
        "已入驻购物中心数量",
        "品牌图片地址",
        "经营模式",
        "业态分类",
      ],
      categoryData: {}
    };
  },
  computed: {
    sort() {
      return this.$store.getters.getSortId("brand");
    },
  },

  watch: {
    filterData: {
      handler(value) {
        let tempData = {};
        if (value) {
          for (let key in value) {
            tempData[key] = value[key];
            this.$set(this.curFilterData, key, tempData[key]);
          }
        }
        const vnodeDirectives = this.$vnode.data.directives;
        if (vnodeDirectives) {
          for (const i in vnodeDirectives) {
            if (vnodeDirectives[i].name === "show") {
              if (vnodeDirectives[i].value === true) {
                this.initTable();
              }
            }
          }
        }
      },
      deep: true,
    },
    pages: {
      handler(value) {
        this.page = value.page;
        this.pageSize = value.pages;
      },
      deep: true,
    },
    filterSelectCategory: {
      handler(value) {
        this.getCategory(value);
      },
      deep: true
    },
    categoryData: {
      handler (value) {
        const vnodeDirectives = this.$vnode.data.directives;
        if (vnodeDirectives) {
          for (const i in vnodeDirectives) {
            if (vnodeDirectives[i].name === "show") {
              if (vnodeDirectives[i].value === true) {
                this.initTable();
              }
            }
          }
        }
      },
      deep: true
    },
    isOpenCategory(val) {
      if (!val) {
        this.$refs.category.$refs.brandSearch.keyword = ''
      }
    }
  },

  methods: {
    clearCategory () {
      this.selectCategory = '';
      this.categoryData = {};
      this.$refs.category.clearCategory();
    },
    getCategory (val) {
      this.categoryData = val
      this.selectCategory = val.name;
      this.isOpenCategory = false;
      this.page = 1;
      this.$emit('categoryData', this.categoryData)
    },
    //初始化过滤器数据
    async initData() {
      await this.initTable();
      // if (this.brandList.length > 0) {
      //   let brandId = this.brandList[0].code;
      //   this.handleSelectBrand(brandId);
      // }
    },
    //请求商品列表数据
    async initTable() {
      let filterCondition = [];
      let filters = [];
      if (this.categoryData.field) {
        // 新品牌分类请求参数
        filters.push({
          field: this.categoryData.field,
          condition: [this.categoryData.condition]
        })
      }
        for (let key in this.curFilterData) {
          let values = this.curFilterData[key] || [];
          if (values.length === 0 || (values.length === 1 && values[0] === "0")) {
            continue;
          }
          let filter = getFilter(key, values);
          filterCondition.push(filter);
          if (key === "industry") {
            filters.push(...getCateFilter(values[0]));
          } else {
            filters.push({
              field: key,
              condition: values,
            });
          }
        }

      let order = ["门店数量", "desc"];
      //高低选择器数
      if (this.highLowFilterData) {
        if (this.highLowFilterData[0] && this.highLowFilterData[1]) {
          order = this.highLowFilterData;
        }
      }
      this.isLoading = true;
      // let params = {
      //   identify: "sg-data-品牌-品牌详情信息",
      //   fields: this.tableFields,
      //   filters: filterCondition,
      //   sort: order,
      //   pages: {
      //     page: this.page || 1,
      //     size: this.pageSize || 10,
      //   },
      // };
      // let res = await GetCommonInfo(params);

      let orderCondition = !this.searchKey ? cloneObj(brandNormalOrder) : {};
      if (!this.searchKey && order && order.length) {
        orderCondition = {
          field: getSortKey(order[0]),
          condition: order[1],
        };
      }
      let params2 = {
        searchSort: this.sort, // 搜索类型，品牌-2
        word: this.searchKey || "", // 搜索关键词
        page: this.page || 1,
        pageSize: this.pageSize || 10,
        filterCondition: filters,
        orderCondition: orderCondition,
        lat: "",
        lon: "",
      };
      let res = await SearchData(params2);
      if (res && res.code === 200) {
        let list = res.data.items || [];
        list = formatList(list, {
          logo: "pic",
          name: "品牌名称",
          tags: "标签",

          consumptionPerPerson: "人均消费",
          storeNumber: "门店数量",
          settledShoppingNumber: "已入驻购物中心数量",
          coverProvinceNumber: "覆盖省数量",
          industry: "经营模式",
          category: "业态分类",
          coverCityNumber: "覆盖市数量",
        });

        // let list = formatFieldsList(res.data || {}, {
        //   品牌id: "code",
        //   品牌图片地址: "pic",
        // });
        // 查询收藏状态
        list = await this.checkListCollectStatus(list);
        this.brandList = list || [];
        // let data = res.data;
        // this.brandList = data.items || [];
        if (this.isFirstGetBrandList) {
          this.isFirstGetBrandList = false;
          if (this.brandList.length > 0) {
            let brandId = this.brandList[0].code;
            this.handleSelectBrand(brandId);
          }
        }
        this.total = res.data.pages.total;
      }
      this.isLoading = false;
    },
    async getCollectListByCodes(codes) {
      let params = {
        identify: "sg-data-品牌-品牌详情信息",
        fields: this.tableFields,
        filters: [
          {
            field: "品牌id",
            cond: 6,
            value: codes,
          },
        ],
        sort: ["门店数量", "desc"],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data || {}, {
          品牌id: "code",
          品牌图片地址: "pic",
        });
        list = list.map((item) => {
          item.isCollect = true;
          return item;
        });
        return list;
      }
      return [];
    },
    //翻页
    handlePageChange(page) {
      this.page = page;
      const pages = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.$emit("changePage", pages);
      this.initTable();
    },
    //更改每页尺寸
    handlerPageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      const pages = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.$emit("changePage", pages);
      this.initTable();
    },
    getTags(_tags) {
      let tags = _tags;
      if (!tags) {
        return "";
      }
      if (typeof tags === "string") {
        tags = tags.split(",");
      }
      let list = [];
      for (let i = 0, n = tags.length; i < n; i++) {
        let tag = tags[i];
        if (tag) {
          list.push(tag);
        }
        if (list.length === 2) {
          break;
        }
      }
      return list.join("/");
    },
    //过滤器响应回调
    // selectFilter(obj) {
    //   if (obj) {
    //     for (let key in obj) {
    //       this.curFilterData[key] = obj[key];
    //       this.$set(this.curFilterData, key, this.curFilterData[key]);
    //     }
    //   }
    //   this.changeFilter();
    //   this.page = 1;
    //   this.initTable();
    // },
    selectFilter(value) {
      let filterObj = {};
      for (let key in value) {
        filterObj[key] = value[key];
      }
      this.page = 1;
      this.$emit("changeFilter", filterObj);
    },
    async goBrandDetail(code) {
      const res = await GetDetailBrowsingRecord({
        module: "brandDetail",
        detailID: code,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/brandDetail", {
          id: code,
        });
      }
    },
    doSearch() {
      this.page = 1;
      this.initTable();
    },
  },
};

export default brandMixin;
