<template>
  <MapListTemplate @changed="handleChangeExpand">
    <div
      class="left-panel no-scrollbar"
      :class="{ 'no-pager': isHidePager }"
      slot="leftPanel"
      v-loading="isLoading"
    >
      <div class="left-navs">
        <ul>
          <li @click="hideCollectList">
<!--            <FilterBtn-->
<!--              :data="industryFilterData"-->
<!--              :filterData="curFilterData"-->
<!--              :isIndustry="true"-->
<!--              :isSelectClose="true"-->
<!--              @selectFilter="selectFilter"-->
<!--            >-->
<!--              <Icon custom="icon-industry" />-->
<!--              {{ cateShowLabel }}-->
<!--            </FilterBtn>-->
            <Poptip trigger="click" transfer placement="top-start" v-model="isOpenCategory" popper-class="custom-poptip">
              <Tooltip :content="cateShowLabel" placement="bottom">
                <span class="category-box" :class="{ active: cateShowLabel !== '品牌分类'}">
                  <Icon custom="icon-industry" />
                  <span class="category-name">
                    {{ cateShowLabel }}
                  </span>
                  <Icon v-if="cateShowLabel !== '品牌分类'" @click.stop="clearCategory" class="icon-status" size="14" type="ios-close-circle-outline" />
                </span>
              </Tooltip>
              <template #content>
                <Category ref="category" @getVal="getCategory" :isSelectCategory="categoryData"></Category>
              </template>
            </Poptip>
          </li>
          <li @click="hideCollectList">
            <FilterBtn
              :data="filterMenusData"
              :filterData="curFilterData"
              :isSelectClose="true"
              @selectFilter="selectFilter"
            >
              <Icon custom="icon-filter"></Icon>
              高级筛选
            </FilterBtn>
          </li>
          <li :class="{ active: isShowCollection }" @click="changeCollectShow">
            <Icon custom="icon-favorite"></Icon>
            我的收藏
          </li>
        </ul>
      </div>
      <div class="left-list-content" :class="{ 'g-login-padding': !isLogin() }">
        <ul>
          <li
            v-for="(item, index) in showBrandList"
            :key="'brand_' + index"
            :class="{ cur: curBrandId === item.code }"
            @click="handleSelectBrand(item.code)"
          >
            <div class="pic cur-pointer" @click.stop="goBrandDetail(item.code)">
              <img v-if="item.pic" :src="item.pic" alt="" />
              <template v-else>
                <span class="pic-name text-ellipsis2 center-middle-box">
                  {{ item["品牌名称"] }}</span>
                <img src="../../assets/images/business/logobg2.png" alt="">
              </template>
            </div>
            <div class="list-cont">
              <div>
                <span class="title text-ellipsis" :title="item['品牌名称']">{{ item["品牌名称"] }}</span>
                <span
                  class="tag"
                  :title="item['业态分类']"
                  v-if="item['业态分类']"
                  :style="getTagStyle(item)"
                  >{{ item["业态分类"] }}</span
                >
                <span
                  class="collect"
                  v-if="!item.isCollect"
                  @click.stop="addCollect(item.code)"
                  >收藏</span
                >
                <span
                  class="collect no-collect"
                  @click.stop="delCollect(item.code)"
                  v-else
                  >取消收藏</span
                >
              </div>
              <div class="list-item">
                <span class="list-item2">
                  人均消费: <a>{{ item["人均消费"] || "--" }}元</a>
                </span>
                <span class="list-item2">
                  门店数量: <a>{{ item["门店数量"] || 0 }}家</a>
                </span>
              </div>
              <div class="list-item">
                入驻购物中心: <a>{{ item["已入驻购物中心数量"] || 0 }}家</a>
                <span
                  class="link hover-show"
                  @click.stop="goBrandDetail(item.code)"
                  >查看详情</span
                >
              </div>
            </div>
            <div class="cur-box"></div>
            <img
              class="triangle-icon"
              src="../../assets/images/icon/triangle.png"
              alt=""
            />
          </li>
          <template v-if="!isLogin()">
            <li class="visitor-login">
              <div role="button" class="visitor-login-btn" @click="gotoLogin">
                登录查看全部信息<img
                  class="visitor-login-icon"
                  :src="require('@/assets/images/double-arror.png')"
                  width="14"
                  height="14"
                />
              </div>
            </li>
          </template>
        </ul>
        <div class="no-data" v-if="!isLoading && showBrandList.length === 0">
          <no-data class="center-middle-box"></no-data>
        </div>
        <!-- <Spin fix v-if="isLoading"></Spin> -->
      </div>
      <PagerLittle
        class="left-list-pager"
        :total="total"
        :page="page"
        :pageSize="pageSize"
        @on-change="handlePageChange"
      ></PagerLittle>
    </div>
    <MapPanel
      ref="mapPanel"
      :isSelectCity="true"
      :deep="deep"
      @loaded="handleMapLoaded"
      @click="handleMapClick"
      @contextmenu="handleBackDeep"
      @selectCity="handleSelectCity"
      @handleBackClick="handleBackDeep"
    >
    </MapPanel>
    <Spin fix v-if="isMapLoading"></Spin>
  </MapListTemplate>
</template>

<script>
import MapPanel from "@/components/MapPanel.vue";
import FilterBtn from "@/components/filter/FilterBtn.vue";
import PagerLittle from "@/components/PagerLittle";
import Category from "@/components/Category.vue";
import {
  brandCates,
  brandFilterMenus,
  getCateLabel,
  brandNormalOrder,
  brandOrderList,
  brandLabelKeys,
  getParams,
} from "js/brandData.js";
import {
  SearchData,
  GetCommonInfo,
  User,
  GetDetailBrowsingRecord,
} from "@/service";
import {
  formatFieldsList,
  formatList,
  login,
  isLogin,
  getCharCodeLength,
  openNewWindow,
} from "js/utils.js";
import { str2coordintes } from "js/map/map.utils.js";
import InfoWindowBrand from "@/components/InfoWindowBrand.vue";
import brandMixin from "./brand";
import collectMixin from "@/mixins/collect.js";
import Vue from "vue";
import normalPic from "@/assets/images/normal_brand.png";
export default {
  props: ["filterData", "searchKey", "filterSelectCategory"],
  data() {
    return {
      showCloseIcon: false,
      isOpenCategory: false,
      selectCategory: '',
      selectCityName: "",
      // brandList: [],
      // curFilterData: {}, // 筛选结果
      curBrandId: "",
      // page: 1,
      // pageSize: 20,
      // total: 0,
      normalPic: normalPic,

      // 收藏相关
      isShowCollection: false,
      collectList: [],

      mapPanel: null,
      isMapLoaded: false,
      deep: 1,

      districtCache: new Map(), // 行政区划缓存
      brandCountCache: new Map(), // 品牌地图数据缓存

      featuresMap: new Map(), // 每一级的features
      deepGeomMap: new Map(), // 每一级的geom
      deepAdCode: {}, // 每个层级
      isShowGroup: true, // 是否显示点位数量
      cityProvinceMap: new Map(), // 省和市的对应关系
      provinceCityMap: new Map(),
      cityNameMap: new Map(),
      isLoadedCityProvince: false,
      isLoading: true,
      isMapLoading: false,
    };
  },
  mixins: [brandMixin, collectMixin],
  computed: {
    industryFilterData() {
      return {
        key: brandCates.key,
        filters: brandCates.submenu,
      };
    },
    cateShowLabel() {
      // const query = this.$route.query
      // console.log('cateShowLabel', query);
      // let indexs
      // if (query.id) {
      //   indexs = query.id.split(',')
      // }
      // let label = "";
      // let values = this.curFilterData[brandCates.key];
      // if (values && values.length > 0) {
      //   let value = values[0];
      //   label = getCateLabel(value);
      // }
      let name = this.selectCategory
      if (this.selectCategory && this.selectCategory.includes('全部'))  {
        name = this.selectCategory.substring(2)
      }
      return name || "品牌分类";
    },
    filterMenusData() {
      return {
        title: "高级筛选",
        icon: "ios-funnel-outline",
        filters: brandFilterMenus,
      };
    },
    showBrandList() {
      if (this.isShowCollection) {
        return this.collectList;
      }
      return this.brandList;
    },
    isHidePager() {
      return this.isShowCollection || this.total <= this.pageSize;
    },
  },
  watch: {
    isShowGroup(newval) {
      if (newval) {
        this.mapPanel.closeWindowInfo();
      }
    },
  },
  methods: {
    // 选择品牌
    handleSelectBrand(brandId) {
      // if (this.curBrandId === brandId) {
      //   return;
      // }
      this.curBrandId = brandId;
      if (!this.isShowGroup) {
        this.isShowGroup = true;
      }
      this.hideCurLayer();
      this.featuresMap.clear();
      this.deep = 1;
      this.getMapStore({ deep: this.deep, brandId });
    },
    isLogin,
    gotoLogin: login,
    // ******** map ********
    // 获取地图中的门店
    async getMapStore({ deep, adcode, brandId, ignoreMap }) {
      this.isMapLoading = true;
      let checkNum = 300;
      let inter = setInterval(() => {
        if (checkNum <= 0) {
          clearInterval(inter);
          return;
        }
        checkNum -= 10;
      }, 10);
      if (!deep) {
        deep = this.deep;
      }
      if (!brandId) {
        brandId = this.curBrandId;
      }
      if (!adcode) {
        adcode = "";
      } else {
        this.deepAdCode[deep] = adcode;
      }
      let levelObj = {
        1: "country",
        2: "province",
        3: "city",
        4: "district",
      };
      let isShowGroup = this.isShowGroup;
      if (levelObj[deep] === "district") {
        isShowGroup = false;
        this.isShowGroup = isShowGroup;
      }

      let districtGeom = "";
      let districtList = [];

      let districtKey = deep + "_" + adcode;
      let districtObj = this.districtCache.get(districtKey);
      if (!districtObj) {
        let searchDeep = this.isLoadedCityProvince ? 1 : 2;
        districtObj = await this.mapPanel.searchDistrict(
          adcode,
          true,
          searchDeep
        );
        this.districtCache.set(districtKey, districtObj);
        if (!this.isLoadedCityProvince) {
          this.initProvinceCity(districtObj.districtList);
          this.isLoadedCityProvince = true;
        }
      }
      if (districtObj) {
        districtGeom = districtObj.bbox;
        districtList = districtObj.districtList;
      }
      if (!ignoreMap && isShowGroup) {
        this.mapPanel.fitBounds(districtGeom);
      }

      let brandKey = brandId + "_" + deep + "_" + adcode;
      let brandCountList = this.brandCountCache.get(brandKey);
      if (!brandCountList) {
        let filters = [
          {
            field: "地区级别",
            cond: 0,
            value: [deep + ""],
          },
        ];
        if (adcode) {
          if (isShowGroup) {
            let adcodes = districtList.map((item) => item.adcode);
            filters = [
              {
                field: "地区编码",
                cond: 6,
                value: adcodes,
              },
            ];
          } else {
            filters = [
              {
                field: "区县编码",
                cond: 0,
                value: [adcode],
              },
            ];
          }
        }
        let params = getParams("品牌-品牌省市区门店数统计", brandId, filters);
        if (!isShowGroup) {
          params = getParams("品牌-门店详情", brandId, filters);
        }
        let res = await GetCommonInfo(params);
        this.isMapLoading = false;
        if (res && res.code === 200) {
          let list = formatFieldsList(res.data || {});
          brandCountList = list;
          this.brandCountCache.set(brandKey, list);
        }
      } else {
        this.isMapLoading = false;
      }
      let features = [];
      if (isShowGroup) {
        features = this.formatGroupFeatures(brandCountList, districtList);
      } else {
        features = this.formatPointFeatures(brandCountList);
        if (!ignoreMap) {
          this.mapPanel.fitBounds(features, true, [100, 100, 100, 100]);
        }
      }
      if (checkNum > 0) {
        clearInterval(inter);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, checkNum);
        });
      }
      this.featuresMap.set(deep, features);

      this.showDeep(deep, ignoreMap);
    },
    formatPointFeatures(list) {
      let features = [];
      for (let i = 0, n = list.length; i < n; i++) {
        let item = list[i];
        let center = str2coordintes(item.lon + "," + item.lat);
        item.isStore = true;

        let feature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: center,
          },
          properties: item,
        };
        features.push(feature);
      }
      return features;
    },
    formatGroupFeatures(list, districtList) {
      let obj = {};
      for (let i = 0, n = list.length; i < n; i++) {
        let item = list[i];
        obj[item["地区编码"]] = item["门店数"];
      }
      let features = [];
      for (let i = 0, n = districtList.length; i < n; i++) {
        let district = districtList[i];
        let adcode = district.adcode;
        let center = district.center;
        let num = obj[adcode] || 0;
        if (!num) {
          continue;
        }
        let feature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: center,
          },
          properties: {
            adcode: adcode,
            center: center,
            name: district.name,
            num: num,
            id: adcode,
          },
        };
        features.push(feature);
      }
      return features;
    },
    // 保存省市关系
    initProvinceCity(provinces) {
      for (let i = 0, n = provinces.length; i < n; i++) {
        let province = provinces[i];
        let cities = province.districtList || [];
        let cityCodes = [];
        for (let j = 0, m = cities.length; j < m; j++) {
          let city = cities[j];
          cityCodes.push(city.adcode);
          this.cityProvinceMap.set(city.adcode, province.adcode);
          this.cityNameMap.set(city.adcode, city.name);
        }
        if (["北京市", "天津市", "上海市", "重庆市"].includes(province.name)) {
          this.cityNameMap.set(province.adcode, province.name);
        }
        this.provinceCityMap.set(province.adcode, cityCodes);
      }
    },
    handleMapLoaded() {
      this.isMapLoaded = true;
    },
    // 切换城市
    async handleSelectCity(city) {
      let cityCode = city.adcode;
      let provinceId = this.cityProvinceMap.get(cityCode);
      let isProvinceCode = false;
      if (!provinceId) {
        provinceId = cityCode;
        cityCode = null;
        isProvinceCode = true;
      }
      this.hideCurLayer();
      if (!this.isShowGroup) {
        this.isShowGroup = true;
      }
      if (this.deep > 3) {
        if (cityCode === this.deepAdCode[2]) {
          this.deep = 3;
          this.showDeep(this.deep);
          return;
        }
      }
      if (provinceId === this.deepAdCode[1]) {
        this.deep = 3;
        this.getMapStore({ deep: 3, adcode: cityCode });
        return;
      }
      this.deep = 2;
      await this.getMapStore({
        deep: 2,
        adcode: provinceId,
        ignoreMap: !isProvinceCode,
      });
      if (isProvinceCode) {
        return;
      }
      this.deep = 3;
      this.getMapStore({ deep: 3, adcode: cityCode });
    },
    // 显示点位
    showDeep(deep, ignoreMap) {
      let features = this.featuresMap.get(deep) || [];
      let options = {};
      if (this.isShowGroup) {
        options = {
          content: this.getMarkerContent,
          anchor: "center",
        };
      } else {
        options = {
          icon: "/images/marker_blue2.png",
          iconSize: [22, 33],
          anchor: "bottom-center",
        };
      }
      this.mapPanel.setMarkers("marker" + deep, features, options);
      let newLayerId = "marker" + deep;
      if (ignoreMap) {
        this.mapPanel.changeLayerShow(newLayerId, false);
      } else {
        this.mapPanel.changeLayerShow(newLayerId, true);
      }
      if (deep === 1) {
        this.mapPanel.changeCityName("");
      } else {
        let adcode = this.deepAdCode[deep] || "";
        let cityName = this.cityNameMap.get(adcode);
        if (cityName) {
          this.mapPanel.changeCityName(cityName);
        }
      }
    },
    getMarkerContent(feature) {
      let num = feature.properties.num;
      return `<div class="group-marker">${num}</div>`;
    },
    // 回退
    handleBackDeep() {
      let deep = this.deep;
      if (deep === 1) {
        return;
      }
      if (!this.isShowGroup) {
        this.isShowGroup = true;
      }
      this.hideCurLayer();
      deep -= 1;
      this.deep = deep;
      this.showDeep(deep);

      let adcode = this.deepAdCode[deep] || "";
      let districtKey = deep + "_" + adcode;
      let districtObj = this.districtCache.get(districtKey);
      let geom = districtObj.bbox;
      this.mapPanel.fitBounds(geom, true);
    },
    hideCurLayer() {
      let deep = this.deep;
      let oldLayerId = "marker" + deep;
      this.mapPanel.changeLayerShow(oldLayerId, false);
    },
    // 点位点击
    handleMapClick(feature) {
      let properties = feature.properties;
      if (properties.isStore) {
        this.openWindowInfo(feature);
        return;
      }
      this.hideCurLayer();
      let deep = this.deep;
      deep += 1;
      let adcode = properties.adcode;
      this.deep = deep;
      this.getMapStore({ deep, adcode });
    },
    openWindowInfo(feature) {
      let properties = feature.properties;
      let center = feature.geometry.coordinates;
      let InfoWindow = Vue.extend(InfoWindowBrand);
      let instance = new InfoWindow({
        propsData: {
          title: properties["品牌名称"],
          list: [
            `名称: ${properties["门店名称"]}`,
            `地址: ${properties["门店地址"]}`,
          ],
          btnText: "查看门店详情",
        },
      });
      instance.$on("doSubmit", async () => {
        let storeId = properties["门店id"];
        let brandId = this.curBrandId;
        const bRes = await GetDetailBrowsingRecord({
          module: "brandDetail",
          detailID: brandId,
        });
        if (bRes && bRes.code == 200 && bRes.data == "success") {
          const res = await GetDetailBrowsingRecord({
            module: "storeDetail",
            detailID: storeId,
          });
          if (res && res.code == 200 && res.data == "success") {
            openNewWindow("/storeDetail", {
              id: storeId,
              brandID: brandId,
            });
          }
        }
      });
      instance.$mount();
      let content = instance.$el;
      this.mapPanel.openInfoWindow(center, content, {
        offset: [0, -23],
      });
    },
    // ******** end map ********
    handleChangeExpand() {
      this.map && this.map.resize();
    },
    async formatCollectList(list) {
      let list2 = formatList(list, {
        logo: "pic",
        name: "品牌名称",
        tags: "标签",

        consumptionPerPerson: "人均消费",
        storeNumber: "门店数量",
        settledShoppingNumber: "已入驻购物中心数量",
        coverProvinceNumber: "覆盖省数量",
        industry: "经营模式",
        category: "业态分类",
        coverCityNumber: "覆盖市数量",
      });
      // let codes = list.map((item) => item.code);
      // list = await this.getCollectListByCodes(codes);
      return list2;
    },
    getTagStyle(item) {
      let name = item["品牌名称"];
      let len = getCharCodeLength(name);
      const titleWidth = (len / 2) * 16;
      let allWidth = titleWidth > 148 ? 164 : titleWidth + 16;
      if (item.isCollect) {
        allWidth += 78;
      } else {
        allWidth += 50;
      }
      return {
        "max-width": `calc(100% - ${allWidth}px)`,
      };
    },
  },
  mounted() {
    this.mapPanel = this.$refs.mapPanel;

    // this.initData();
  },
  components: {
    MapPanel,
    FilterBtn,
    PagerLittle,
    Category
  },
};
</script>

<style lang="less" scoped>
/deep/.map-panel {
  .map-tools {
    right: 100px;
  }
}
</style>
<style lang="less">
.category-box {
  display: inline-flex;
  align-items: center;
}
.category-name {
  display: inline-block;
  width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.circle-blue {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: hsla(180, 100%, 50%, 0.7);
  border: 1px solid hsl(180, 100%, 40%);
  box-shadow: hsl(180, 100%, 50%) 0px 0px 1px;
}
.group-marker {
  width: 57px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #fff;
  background-image: url("../../assets/images/marker/marker_group.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
