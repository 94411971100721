var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "brand-filter" }, [
    _c(
      "div",
      { staticClass: "brand mrt" },
      [
        _c("div", { staticClass: "filter-title" }, [_vm._v("品牌分类：")]),
        _c(
          "div",
          { staticClass: "brand-item-wrap" },
          _vm._l(_vm.cateMenusData, function (item, index) {
            return _c(
              "div",
              {
                key: item.code,
                staticClass: "brand-item mrt10",
                class: {
                  "active-title": _vm.checkFilterMenu(item, _vm.subMenu),
                },
                on: {
                  click: function ($event) {
                    return _vm.selectMenuCate(item, index)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        ),
        _c(
          "Poptip",
          {
            attrs: {
              trigger: "click",
              placement: "bottom",
              "popper-class": "custom-poptip",
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("BrandSearch", {
                      on: {
                        getVal: function ($event) {
                          return _vm.getCategory($event, true)
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.isOpenCategory,
              callback: function ($$v) {
                _vm.isOpenCategory = $$v
              },
              expression: "isOpenCategory",
            },
          },
          [
            _c("div", { staticClass: "tooltip" }, [
              _c("img", {
                attrs: {
                  width: "14px",
                  height: "14px",
                  src: require("../../assets/images/brand/search.png"),
                  alt: "",
                },
              }),
              _vm._v("检索品类 "),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.subMenu &&
              _vm.subMenu.length > 1 &&
              _vm.subMenu[0].name !== "不限",
            expression:
              "subMenu && subMenu.length > 1 && subMenu[0].name !== '不限'",
          },
        ],
        staticClass: "brand",
      },
      [
        _c("div", { staticClass: "filter-title" }),
        _c(
          "div",
          { staticClass: "brand-item-wrap sub-brand-wrap" },
          _vm._l(_vm.subMenu, function (subItem, index) {
            return _c(
              "div",
              {
                key: subItem.code,
                staticClass: "brand-item",
                class: {
                  "active-title": _vm.curSubMenu.code === subItem.code,
                },
                on: {
                  click: function ($event) {
                    return _vm.selectMenuCate(subItem, index)
                  },
                },
              },
              [_vm._v(" " + _vm._s(subItem.name) + " ")]
            )
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.supMenu.length,
            expression: "supMenu.length",
          },
        ],
        staticClass: "brand",
      },
      [
        _c("div", { staticClass: "filter-title" }),
        _c(
          "div",
          { staticClass: "brand-item-wrap sub-brand-wrap bt" },
          _vm._l(_vm.supMenu, function (subItem, index) {
            return _c(
              "div",
              {
                key: subItem.code,
                staticClass: "brand-item",
                class: {
                  "active-title": _vm.checkFilterSub(subItem),
                },
                on: {
                  click: function ($event) {
                    return _vm.selectCateFilter1(subItem, index)
                  },
                },
              },
              [_vm._v(" " + _vm._s(subItem.name) + " ")]
            )
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "more-filter",
        class: {
          "more-filter-style2":
            _vm.subMenu &&
            _vm.subMenu.length === 1 &&
            _vm.subMenu[0].label === "不限",
        },
      },
      [
        _c("div", { staticClass: "filter-title" }, [_vm._v("更多筛选：")]),
        _c(
          "div",
          { ref: "moreWrap", staticClass: "more-wrap" },
          [
            _c(
              "div",
              { staticClass: "more-item-wrap" },
              _vm._l(_vm.moreMenusData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "more-item" + item.label + index,
                    staticClass: "more-item",
                  },
                  [
                    _c(
                      "span",
                      { class: { "active-title": _vm.checkFilterCur2(item) } },
                      [_vm._v(_vm._s(item.label))]
                    ),
                    _c("Icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.isVip,
                          expression: "item.isVip",
                        },
                      ],
                      staticClass: "img-vip",
                      attrs: { type: "ios-lock-outline" },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _c("transition", { attrs: { name: "slide-up" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMoreSelectBox,
                      expression: "showMoreSelectBox",
                    },
                  ],
                  staticClass: "more-select",
                },
                _vm._l(_vm.moreMenusData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "more-select" + item.label + index,
                      staticClass: "more-select-item",
                    },
                    [
                      _c("span", { staticClass: "more-select-item-title" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                      _c(
                        "span",
                        { staticClass: "more-select-item-span-wrap" },
                        _vm._l(item.submenu, function (subItem, subIndex) {
                          return _c(
                            "span",
                            {
                              key:
                                "more-select" +
                                item.label +
                                subItem.label +
                                subIndex,
                              staticClass: "more-select-item-span",
                              class: {
                                "active-title": _vm.checkFilterCur(
                                  item,
                                  subItem
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectMenuFilter(item, subItem)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(subItem.label) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }