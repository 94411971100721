var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap" },
    [
      _c("Header", {
        attrs: { mode: 2, searchKey: _vm.searchKey },
        on: { search: _vm.doSearch },
      }),
      _c(
        "div",
        { staticClass: "main-wrap" },
        [
          _c("BrandMap", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowMap,
                expression: "isShowMap",
              },
            ],
            ref: "brandMap",
            attrs: {
              filterData: _vm.filterData,
              pages: _vm.pages,
              searchKey: _vm.searchKey,
              filterSelectCategory: _vm.categoryData,
            },
            on: {
              changeFilter: _vm.changeFilter,
              changePage: _vm.changePage,
              categoryData: _vm.getCategoryData,
            },
          }),
          _c("BrandList", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isShowMap,
                expression: "!isShowMap",
              },
            ],
            ref: "brandList",
            attrs: {
              pages: _vm.pages,
              filterData: _vm.filterData,
              searchKey: _vm.searchKey,
              filterSelectCategory: _vm.categoryData,
              isShowMap: _vm.isShowMap,
            },
            on: {
              changeFilter: _vm.changeFilter,
              changePage: _vm.changePage,
              categoryData: _vm.getCategoryData,
            },
          }),
          _c(
            "div",
            {
              staticClass: "change-btn",
              class: { "change-btn-list": !_vm.isShowMap },
              on: { click: _vm.showMapOrList },
            },
            [
              _vm.isShowMap
                ? [
                    _c("Icon", {
                      staticClass: "change-btn-icon",
                      attrs: { custom: "icon-mode-list" },
                    }),
                    _vm._v(" 列表模式 "),
                  ]
                : [
                    _c("Icon", {
                      staticClass: "change-btn-icon",
                      attrs: { custom: "icon-mode-map" },
                    }),
                    _vm._v(" 地图模式 "),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }